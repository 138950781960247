import React, { useEffect, useState } from "react";
import tasdiqlash from "../Assets/tasdiqlash.svg";
import bekorqilish from "../Assets/bekorqilish.svg";
import bloklash from "../Assets/bloklash.svg";
import logoutimg from "../Assets/loguot.svg"
import { useNavigate } from "react-router-dom";
// import swiper
import "swiper/css";

import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
// chakra modal
export default function Moder() {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [pagination, setPagination] = useState([])
  const [reason, setReason] = useState('')

  const resetInput = () => {
    setReason('');
  };
  // button type 
  const [modalType, setModalType] = useState(null);


  const handleButtonClick = (type) => {
    setModalType(type);
  };

  useEffect(() => {
    const token = getCookie("token");

    const url = new URL(
      "https://api.frossh.uz/api/announcement/get-by-moderation"
    );
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    setLoading(true);

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setAnnouncements(data.result.data);
        setLoading(false);
        setPagination(data?.result?.links)
        console.log(data?.result?.links);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };
  // accept function
  const accept = (id) => {
    const url = new URL(`https://api.frossh.uz/api/announcement/accept/${id}`);

    const token = getCookie("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    setLoading(true);

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Xato:", error);
        setLoading(false);
      });
  };
  // reject function
  const reject = (id, reason) => {
    const url = new URL(`https://api.frossh.uz/api/announcement/reject`);

    const token = getCookie("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const requestBody = JSON.stringify({
      id: id,
      reason: reason
    });

    setLoading(true);

    fetch(url, {
      method: "POST",
      headers,
      body: requestBody
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Xato:", error);
        setLoading(false);
      });
  };
  // block function
  const block = (id, reason) => { // reason ni funksiyaga qo'shing
    const url = new URL(`https://api.frossh.uz/api/announcement/block`);

    const token = getCookie("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const requestBody = JSON.stringify({
      id: id,
      reason: reason
    });
    setLoading(true);

    fetch(url, {
      method: "POST",
      headers,
      body: requestBody
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Xato:", error);
        setLoading(false);
      });
  };
  const fetchData = (param) => {
    const token = getCookie("token");

    const url = param ? param : new URL(
      "https://api.frossh.uz/api/announcement/get-by-moderation"
    );
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    setLoading(true);

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setAnnouncements(data?.result?.data);
        setPagination(data?.result?.links)

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };
  // logout function
  const logoutfunction = () => {
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    localStorage.removeItem('tel')
    navigate('/');

    const token = getCookie("token");
    fetch("https://api.frossh.uz/api/auth/logout", {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('HTTP error, status = ' + response.status);
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
  };

  return (
    <div className="moder">


      {loading && <div className="loader"></div>}
      <div className="container">
        {announcements?.map((item, index) => (
          <div className="card" key={index}>
            <Swiper
              slidesPerView={1}
              autoplay={{ delay: 3000 }}
              loop
              navigation={true}
              modules={[Autoplay, Navigation]}
            >
              {item?.images?.map((slide) => (
                <SwiperSlide className="slide-item" key={slide?.id}>
                  <img
                    src={`https://api.frossh.uz/${slide?.path}`}
                    alt="slide-item"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="card-text">
              <p>{item.price_uzs_formatted}UZS</p>
              <p>{item.slug}</p>
              <span>foydalanuvchi malumotlari:</span>
              <span>{item.user.phone_number}</span>
            </div>

            <div className="controler">
              <div className="last">
                <span>{item.user.last_name}</span>
                <span>{item.address}</span>
              </div>
              <div className="control">
                <button onClick={() => accept(item.id)}>
                  <img src={tasdiqlash} alt="" />
                </button>
                <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => handleButtonClick('reject')} >
                  <img src={bekorqilish} alt="" />
                </button>
                <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => handleButtonClick('block')} >
                  <img  id="block" src={bloklash} alt="" />
                </button>
              </div>
              <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <span className="input-group-text">{modalType === "reject" ? "Bekor qilish sababini kiriting!" : "Block qilish sababini kiriting!"}</span>
                      <input
                        type="text"
                        aria-label="First name"
                        className="form-control"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </div>
                    <div className="modal-footer">

                      <button onClick={() => {
                        modalType === "reject" ? reject(item.id, reason) : block(item.id, reason);
                        resetInput();
                      }} type="button" className="btn btn-primary  " data-bs-dismiss="modal" >Save</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        ))}
      </div>


      <div className="paginations">
        {
          pagination?.map((item) => (
            <button


              key={item?.label}
              dangerouslySetInnerHTML={{
                __html: item?.label
                  ?.replace(/\b(Previous|Next)\b/g, "")
                  ?.trim(),
              }}
              onClick={() => {

                if (item.label === "..." || item.active) {
                  return null

                }
                fetchData(item?.url)
              }}
              className={item?.active ? "active" : undefined}
              disabled={!item?.url || !announcements?.length}
            />
          ))



        }
      </div>




      <button onClick={() => logoutfunction()} id="logout" >
        <img src={logoutimg} alt="" />
      </button>
    </div>
  );
}
